export const createSetupUiFlippers = (global = window) => (newFlippers) => {
  const oldFlippers = global?.e?.uiFlippers || [];

  global.e = {
    ...global.e,
    uiFlippers: removeDuplicates([...newFlippers, ...oldFlippers])
  };
};

const removeDuplicates = (array) => [...new Set(array)];

import { setupUiFlippers, setupTestMode } from './utils/e2e-test';

const FLIPPERS_IN_TESTS = [
  'ui_datagrid_column_visibility',
  'ui_datagrid_debounce_server',
  'ui_datagrid_bulk_update_selection',
  'ui_validator_uses_eds_component',
  'ui_theme_auto_selection',
  'ui_pagination_uses_eds_component'
];

setupUiFlippers(FLIPPERS_IN_TESTS);
setupTestMode();
